import React from "react"
import { graphql, StaticQuery } from "gatsby"

import loadable from "@loadable/component"

import { ctaPropsFromSanityCTA } from "./utils"

const RecentArticlesFeature = loadable(() =>
  import("../components/recent-articles-feature")
)

const test = data => data._type === "recentArticlesFeature"

const create = ({ titleText, subtitleText, cta }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          graphCMS {
            posts(
              where: { publishDate_not: null }
              orderBy: publishDate_DESC
              first: 5
            ) {
              slug
              metaTitle
              metaDescription
              title
              body
              excerpt
              image
              thumbnail
              readingTime
              publishDate
              updatedAt
              author {
                name
                image
              }
            }
          }
        }
      `}
      render={data => (
        <RecentArticlesFeature
          title={titleText}
          subtitle={subtitleText}
          articles={data.graphCMS.posts}
          cta={ctaPropsFromSanityCTA(cta)}
        />
      )}
    />
  )
}

export const fragment = graphql`
  fragment RecentArticlesFeatureAttributes on SanityRecentArticlesFeature {
    _type
    titleText
    subtitleText
    cta {
      ...CallToActionAttributes
    }
  }
`

export default { test, create }
