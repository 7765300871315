import React from "react"

import { useWidthBreakpoints, Breakpoint } from "../../../hooks/use-breakpoints"

import PageSection from "../../page-section"
import StackedContainer from "../../stacked-container"
import Link from "../../link"
import VinomofoLogo from "../../vinomofo-logo"

import FooterText from "./footer-text"
import styles from "./legals.module.scss"

const Logo = () => {
  return (
    <Link link="/">
      <VinomofoLogo />
    </Link>
  )
}

const breakpoints = [Breakpoint("large", [900, null])]

const Legals = () => {
  const [breakpoint, breakpointRef] = useWidthBreakpoints(breakpoints)
  const isHorizontal = "large" === breakpoint

  return (
    <PageSection backgroundColor="black" spaceBefore={4}>
      <div ref={breakpointRef} className={styles[breakpoint]}>
        <StackedContainer space={4}>
          <StackedContainer
            horizontal={isHorizontal}
            distribute={isHorizontal ? "fill" : "start"}
            space={1}
          >
            <div className={styles.contentBlock}>
              <div className={styles.title}>Hey Kids!</div>

              <FooterText />
            </div>

            <div className={styles.contentBlock}>
              <div className={styles.title}>Seriously</div>
              <p>
                At Vinomofo, we love our wine, but we like to also lead long and
                happy lives, and be good to the world and the people in it. We
                all try to drink responsibly, in moderation, and we really hope
                you do too.
              </p>
              <p>Don’t be that guy...</p>
            </div>
          </StackedContainer>
          <div className={styles.copyright}>
            <Logo />
            <p>Nope, we don't do the copyright &copy; thing.</p>
          </div>
        </StackedContainer>
      </div>
    </PageSection>
  )
}

export default Legals
